/* eslint-disable local-rules/require-data-cy */
import { useEffect } from 'react';

import LoginPageContainer from '@components/LoggedOutContainer';
import { usePageRegionAlerts } from '@components/PageRegion';
import Separator from '@components/Separator';
import Spinner from '@components/Spinner';
import Button from '@coral/components/Button';
import Icon, { Icons } from '@coral/components/Icon';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { normalizeUrl } from '@utils/normalizeUrl';
import setWindowLocationHref from '@utils/setWindowLocationHref';

import useSSORedirectURL from './hooks/useSSORedirectURL';
import useSSOStatus from './hooks/useSSOStatus';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const { showErrorAlert } = usePageRegionAlerts();
  const { query } = useSnorkelRouter();
  const { loading, ssoSettings } = useSSOStatus();

  const getSSORedirectURL = useSSORedirectURL(ssoSettings);

  const handleRedirect = async () => {
    const path = await getSSORedirectURL();

    if (!path) return;
    setWindowLocationHref(normalizeUrl(path));
  };

  useEffect(() => {
    if (!query.sso_login_error) return;
    showErrorAlert({
      message: String(query.sso_login_error),
      origin: 'LoginPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginPageContainer>
      {loading ? (
        <div className="my-8">
          <Spinner>Loading</Spinner>
        </div>
      ) : (
        <>
          {ssoSettings && ssoSettings.sso_enabled && (
            <>
              <div className="mx-2">
                <Button onClick={handleRedirect} className="w-full px-4">
                  <div className="py-1 text-xs text-gray-700">
                    Sign in with SSO
                  </div>
                  <Icon name={Icons.ARROW__RIGHT} />
                </Button>
              </div>
              <Separator className="mb-2 mt-4">OR</Separator>
            </>
          )}
          <LoginForm />
        </>
      )}
    </LoginPageContainer>
  );
};

export default LoginPage;

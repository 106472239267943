import { useState } from 'react';

import type { SamlIdpSettings, SamlSpSettings, SsoSettings } from '@api/tdm';
import { usePageRegionAlerts } from '@components/PageRegion';
import useEffectAbortable from '@hooks/useEffectAbortable';
import getNotificationMetaData from '@hooks/useMakeOnRequestFailed/utils/getNotificationMetaData';
import useRequest from '@hooks/useRequestWithLogging';
import type { RequestError } from '@utils/api';
import { ssoApi } from '@utils/api/serverRequests';
import getErrorMessage from '@utils/getErrorMessage';

export const defaultSamlIDPSettings: SamlIdpSettings = {
  entity_id: '',
  sso_url: '',
  sso_binding: '',
  x509_cert: '',
};
export const defaultSamlSpSettings: SamlSpSettings = {
  entity_id: '',
  acs_binding: '',
  acs_url: '',
  name_id_format: '',
  signed_response: false,
  x509_cert: '',
};
export const defaultSsoSettings: SsoSettings = {
  sso_enabled: false,
  sso_required: false,
};

const useSAMLSettings = () => {
  const request = useRequest();
  const { showErrorAlert, showSuccessAlert } = usePageRegionAlerts();
  const [ssoSettings, setSSOSettings] =
    useState<SsoSettings>(defaultSsoSettings);
  const [samlIDPSettings, setSamlIDPSettings] = useState<SamlIdpSettings>(
    defaultSamlIDPSettings,
  );
  const [samlSPSettings, setSamlSPSettings] = useState<SamlSpSettings>(
    defaultSamlSpSettings,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const loadSettings = async (signal: AbortSignal) => {
    setLoading(true);
    const [ssoSettingsResp, samlSettingsResp] = await Promise.all([
      request(ssoApi.getSsoSettingsSsoSettingsGet, {}),
      request(ssoApi.getSamlSettingsSsoSettingsSamlGet, {}),
    ]);

    if (signal.aborted) return;
    setSSOSettings(ssoSettingsResp);
    setSamlIDPSettings(
      samlSettingsResp?.idp_settings || defaultSamlIDPSettings,
    );
    setSamlSPSettings(samlSettingsResp?.sp_settings || defaultSamlSpSettings);
    setLoading(false);
  };

  useEffectAbortable(loadSettings, []);

  const showError = (e: RequestError, message: string) => {
    showErrorAlert({
      message: getErrorMessage(e, message),
      metaData: getNotificationMetaData(e, {}),
      origin: 'useSAMLSettings',
    });
  };

  const handleSamlSubmit = async (
    newSamlIDPSettings: SamlIdpSettings,
    newSsoSettings: SsoSettings,
  ) => {
    if (newSsoSettings.sso_enabled) {
      try {
        await request(
          ssoApi.samlSettingsSsoSettingsSamlPost,
          { samlIdpSettings: newSamlIDPSettings },
          { surfaceError: true },
        );
        setSamlIDPSettings(newSamlIDPSettings);
      } catch (e: any) {
        showError(e, 'Failed to save SAML IDP settings');

        return;
      }
    }

    try {
      await request(
        ssoApi.ssoSettingsSsoSettingsPost,
        { ssoSettings: newSsoSettings },
        { surfaceError: true },
      );
      setSSOSettings(newSsoSettings);
    } catch (e: any) {
      showError(e, 'Failed to save SSO settings');

      return;
    }

    showSuccessAlert({
      message: 'SSO settings saved',
      origin: 'useSAMLSettings',
    });
  };

  return {
    loading,
    ssoSettings,
    samlIDPSettings,
    samlSPSettings,
    handleSamlSubmit,
  };
};

export default useSAMLSettings;
